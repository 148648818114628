@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

/* Custom styles for the dots */

.slider-dots {
  display: flex !important;
  justify-content: center;
  position: relative;
  margin-top: -40px;
  z-index: 2;
}

.slider-dots li {
  list-style: none;
  margin: 0 5px;
  cursor: pointer;
}

.slider-dots li button {
  height: 10px;
  width: 10px;
  border-radius: 5px;
  background-color: white;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: all;
  color: transparent;
}

.slider-dots li.slick-active button {
  background-color: #8dd3bb !important;
  width: 32px;
}

.custom-select {
  padding: 12px;
  background-color: transparent;
  border: 1px solid #112211;
  border-radius: 4px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
}

.custom-select {
  background-image: url('data:image/svg+xml;utf8,<svg fill="black" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
}

.countselect {
  width: 100%;
}
.region {
  width: 100%;
}

.upload-container {
  position: relative;
  width: 100%;
}

.hidden {
  display: none;
  width: 100%;
}

.upload-label {
  display: inline-block;
  padding: 8px;
  cursor: pointer;
  width: 100%;
}

.upload-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.ProfileShadow {
  box-shadow: 0px 8px 12px 0px rgba(62, 63, 122, 0.04);
}

.XpBox {
  padding: 15px;
  width: max-content;
  border-radius: 12px;
  background: linear-gradient(180deg, #ffe384 0%, #dcad0e 100%);
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.XpColor {
  background: linear-gradient(180deg, #09130c 0%, #28a745 100%) !important;
  box-shadow: (0px 4px 4px rgba(177, 177, 177, 0.5)) !important;
}

.UpcomingStyle {
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 8px 12px 0px rgba(62, 63, 122, 0.04);
}

.ShadowMain {
  box-shadow: 0px 8px 12px 0px rgba(62, 63, 122, 0.04);
}

.RedemVoucher {
  background: #71e191;
}

.Voutchers:nth-child(odd) {
  background: linear-gradient(180deg, #fcdb00 0%, #f7b500 100%) !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.VoutchersGolden {
  background: linear-gradient(180deg, #fcdb00 0%, #f7b500 100%);
}

.VoutchersGreen {
  background: #71e191;
}

.custom-scroll {
  scrollbar-width: thin;
}

.css-13cymwt-control {
  height: 44px !important;
}

.css-1u9des2-indicatorSeparator {
  display: none;
}

.css-tj5bde-Svg {
  color: black;
}

.rmdp-container {
  width: 100%;
  height: 100% !important;
  display: flex;
  justify-content: center;
}

.rmdp-input {
  width: 100%;
  height: 90% !important;
  border: 0px solid !important;
}

/* // Double Range Slider CSS */

.multi-range-slider .bar {
  height: 2px;
}

.multi-range-slider .bar .bar-left {
  height: 2px;
  padding: 0;
}

.multi-range-slider .thumb::before {
  border: none !important;
  box-shadow: none !important;
}

.multi-range-slider .caption {
  display: none !important;
}

.PersonalDeatilsFlight .css-13cymwt-control {
  height: 48px !important;
  border-radius: 6px;
}

.number-input::-webkit-outer-spin-button,
.number-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.number-input {
  -moz-appearance: textfield;
}

/* /// Weather Colors */
.WeatherCls {
  background-color: #5ca0f2;
  background-image: radial-gradient(
      circle at top center,
      #f5f7f6,
      rgba(245, 247, 246, 0) 50%
    ),
    linear-gradient(315deg, #5ca0f2 5%, rgba(92, 160, 242, 0.8) 74%);
}

/* .ActivePage {
    filter: grayscale(50%);
} */

/* /// Dummy Css */

/* ================== */

@media screen and (max-width: 640px) {
  .XpBox {
    width: 100% !important;
  }
}

.next-button {
  position: absolute;
  top: -20%;
  border-radius: 50%;
  background-color: #007bff;
  height: 40px;
  width: 40px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  cursor: pointer;
  z-index: 1;
  right: 0;
}

.prev-button {
  position: absolute;
  top: -20%;
  border-radius: 50%;
  background-color: #007bff;
  height: 40px;
  width: 40px;
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  right: 5%;
}

/* Styles for smaller screens */
@media (max-width: 1000px) {
    .prev-button{
      left: -5%;
      top: 40%;
    }
    .next-button{
      top: 40%;
      right: -5%;
    }


}
